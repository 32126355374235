import { defineStore } from 'pinia'
import { useRouter } from 'vue-router'

// collection settings
export const COLLECTION_SETTINGS_COLUMNS = [
  'description',
  'role',
  'nrOfDocuments',
]
export const COLLECTION_SETTINGS = {
  columnSelection: COLLECTION_SETTINGS_COLUMNS,
  itemsPerPage: 24,
  sort: {
    field: 'colId',
    direction: 'asc',
  },
  view: 'card',
}

// document settings
export const DOCUMENT_SETTINGS_COLUMNS = [
  'docId',
  'uploader',
  'labels',
  'uploadTimestamp',
  'nrOfPages',
]
export const DOCUMENT_SETTINGS = {
  columnSelection: DOCUMENT_SETTINGS_COLUMNS,
  itemsPerPage: 24,
  sort: {
    field: 'docId',
    direction: 'asc',
  },
  view: 'card',
}

// page settings
export const PAGE_SETTINGS_COLUMNS = [
  'imgFileName',
  'ctStatus',
  'ctUserName',
  'lastModified',
  'nrOfWordsInLines',
  'nrOfLines',
  'nrOfRegions',
  'labels',
  'ctClientName',
  'ctModelName',
  'ctRecognitionType',
]
export const PAGE_SETTINGS = {
  columnSelection: PAGE_SETTINGS_COLUMNS,
  itemsPerPage: 24,
  colSize: 12,
  sort: {
    field: 'pageNr',
    direction: 'asc',
  },
  view: 'card',
}

export const COMPLETED_TOURS = {
  deskCompleted: false,
  editorCompleted: false,
}

export const useConfig = defineStore('config', () => {
  const config = ref(null)
  const pageSettings = ref(null)
  const docSettings = ref(null)
  const collectionSettings = ref(null)
  const shouldShowWelcome = ref(false)

  function updateConfig(_config) {
    const userApi = useUser()
    const { userProfile } = useKeycloak()
    config.value = _config
    userApi.updateUserConfig({
      userId: userProfile.UserId,
      config: config.value,
    })
  }

  async function fetchPageSettings() {
    const userApi = useUser()
    const { userProfile } = useKeycloak()
    try {
      pageSettings.value = await userApi.fetchPageSettings({
        userId: userProfile.UserId,
      })
    } catch (error) {
      console.log(error)
    }

    // if pageSettings is null or undefined , use DEFAULT_SETTINGS
    if (!pageSettings.value || pageSettings.value === undefined) {
      pageSettings.value = PAGE_SETTINGS
    }
    if (!pageSettings.value?.sort?.field) {
      pageSettings.value.sort.field = 'pageNr'
    }
    if (!pageSettings.value?.sort?.direction) {
      pageSettings.value.sort.direction = 'asc'
    }
    if (pageSettings.value.columnSelection.length === 0) {
      pageSettings.value.columnSelection = PAGE_SETTINGS_COLUMNS
    }
    return pageSettings.value
  }

  async function fetchDocSettings() {
    const userApi = useUser()
    const { userProfile } = useKeycloak()
    try {
      docSettings.value = await userApi.fetchDocumentSettings({
        userId: userProfile.UserId,
      })
    } catch (error) {
      console.log(error)
    }
    // if docSettings is null or undefined , use DEFAULT_SETTINGS
    if (!docSettings.value || docSettings.value === undefined) {
      docSettings.value = DOCUMENT_SETTINGS
    }
    if (!docSettings.value?.sort?.field) {
      docSettings.value.sort.field = 'docId'
    }
    if (!docSettings.value?.sort?.direction) {
      docSettings.value.sort.direction = 'asc'
    }
    if (docSettings.value.columnSelection.length === 0) {
      docSettings.value.columnSelection = DOCUMENT_SETTINGS_COLUMNS
    }
    return docSettings.value
  }

  async function fetchCollectionSettings() {
    const userApi = useUser()
    const { userProfile } = useKeycloak()
    try {
      collectionSettings.value = await userApi.fetchCollectionSettings({
        userId: userProfile.UserId,
      })
    } catch (error) {
      console.log(error)
    }

    // if collectionSettings is null or undefined , use DEFAULT_SETTINGS
    if (!collectionSettings.value || collectionSettings.value === undefined) {
      collectionSettings.value = COLLECTION_SETTINGS
    }
    if (!collectionSettings.value?.sort?.field) {
      collectionSettings.value.sort.field = 'colId'
    }
    if (!collectionSettings.value?.sort?.direction) {
      collectionSettings.value.sort.direction = 'asc'
    }
    if (collectionSettings.value.columnSelection.length === 0) {
      collectionSettings.value.columnSelection = COLLECTION_SETTINGS_COLUMNS
    }
    return collectionSettings.value
  }

  async function updatePageSettings(_pageSettings) {
    const userApi = useUser()
    const { userProfile } = useKeycloak()
    if (_pageSettings) {
      pageSettings.value = _pageSettings
    }
    let copyObj = JSON.parse(JSON.stringify(pageSettings.value))

    userApi.updatePageSettings({
      userId: userProfile.UserId,
      settings: copyObj,
    })
  }

  async function updateDocSettings(_docSettings) {
    const userApi = useUser()
    const { userProfile } = useKeycloak()
    if (_docSettings) {
      docSettings.value = _docSettings
    }
    let copyObj = JSON.parse(JSON.stringify(docSettings.value))
    userApi.updateDocumentSettings({
      userId: userProfile.UserId,
      settings: copyObj,
    })
  }

  async function updateCollectionSettings(_collectionSettings) {
    const userApi = useUser()
    const { userProfile } = useKeycloak()
    if (_collectionSettings) {
      collectionSettings.value = _collectionSettings
    }
    let copyObj = JSON.parse(JSON.stringify(collectionSettings.value))
    userApi.updateCollectionSettings({
      userId: userProfile.UserId,
      settings: copyObj,
    })
  }

  async function fetchConfig() {
    const { $features } = useNuxtApp()
    try {
      const userApi = useUser()
      const { userProfile } = useKeycloak()
      const configCookie = useCookie('transkribus-config')
      const runtimeConfig = useRuntimeConfig()
      const router = useRouter()
      const PRODUCTION_USER_ID_THRESHOLD = 351199

      let data = await userApi.fetchUserConfig({
        userId: userProfile.UserId,
      })

      const userIdThreshold =
        runtimeConfig.public.name === 'alpha' ? 0 : PRODUCTION_USER_ID_THRESHOLD

      // Consider a user new if either:
      // 1. The API explicitly returns noEntry: true
      // 2. The config has no non-language settings and their ID is above threshold
      // 3. The user hasn't completed the welcome flow (loggedInOnce not set)
      const configWithoutLanguage = { ...data }
      delete configWithoutLanguage?.locale

      const hasNoRelevantConfig =
        !configWithoutLanguage ||
        Object.keys(configWithoutLanguage || {}).length === 0
      const cookieSet = configCookie.value?.welcomeFlowCompleted

      const isAboveThreshold = parseInt(userProfile.UserId) > userIdThreshold
      const hasNotCompletedWelcome = !data?.loggedInOnce

      const isNewUser =
        (data?.noEntry || hasNoRelevantConfig || hasNotCompletedWelcome) &&
        isAboveThreshold &&
        !cookieSet

      if (isNewUser) {
        // Preserve language setting if it exists
        config.value = data?.locale ? { locale: data.locale } : {}
        shouldShowWelcome.value = true

        // Only redirect if not already on welcome page

        if (router.currentRoute.value.path !== '/welcome') {
          router.push('/welcome')
        }
      } else {
        config.value = data || {}
        shouldShowWelcome.value = false
      }

      const createdAtDate = new Date(config.value?.createdAt)
      const cutoffDate = new Date('2025-04-01T00:00:00.000Z')

      let shouldSeeOnboarding = createdAtDate > cutoffDate

      // if the user registered before 01.04.2025, set the onboarding to completed
      if (!shouldSeeOnboarding) {
        config.value = {
          ...config.value,
          onboardingTours: {
            deskCompleted: true,
            editorCompleted: true,
          },
        }
      }

      // only for testing:
      // config.value = {
      //   ...config.value,
      //   onboardingTours: {
      //     deskCompleted: false,
      //   },
      // }
    } catch (e) {
      console.error('Error fetching config:', e)
      config.value = {}
      shouldShowWelcome.value = false
    }
  }

  return {
    config,
    pageSettings,
    collectionSettings,
    docSettings,
    shouldShowWelcome,
    updateConfig,
    fetchConfig,
    updatePageSettings,
    fetchPageSettings,
    updateCollectionSettings,
    fetchCollectionSettings,
    updateDocSettings,
    fetchDocSettings,
  }
})
